<template>

    <div class="container">
        <div class="row">
            <div class="col">
                <filters-menu
                    emit-method="get-filters"
                    @get-filters="getFilters"
                    :filters="filters"
                />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <date-picker
                    @get-filter-dates="getFilterDates"
                    :filter-dates="this.filterDates"
                />
            </div>
            <div class="col">
                <div class="d-flex align-items-center">
                    <span>Ordoneaza dupa:</span>
                    <select-dropdown
                        :select-model="orderSelectModel"
                        emit-method="get-order-select-option"
                        @get-order-select-option="getOrderSelectOption"
                        groupClasses="ml-1 mb-0"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <template v-for="filter in filters">
                    <div v-if="filter.value!=null">
                        <selected-filter
                            :filter="filter"
                            groupClasses="mr-1 mb-0 gap-12"
                            emit-method="get-filters"
                            @get-filters="getFilters"
                        />
                    </div>
                </template>
            </div>
        </div>
        <div class="row">

            <div class="col">
                <invoices-table-listing
                    :authType="authType"
                    :headers="computedHeaders"
                    :get-data="getInvoiceListingData"
                    :get-data-payload="prepareFiltersForServer()"
                    :total="invoiceTotal"
                    :items="invoiceListingData"
                    :items-per-page="10"
                >
                    <template v-slot:action-buttons>
                        <!--                                               @click="createPacks(item)" :loading="item.createloading" :disabled="createloading"-->
<!--                        <v-btn color="success" v-on:click="generateContract($event)">-->
<!--                            Creaza contract-->
<!--                        </v-btn>-->
                        <v-btn color="success" v-on:click="generateInvoiceWithObservations($event)">
                            Adauga observatii
                        </v-btn>
                        <!--                       @click="excludeRequest(item)" :loading="item.cancelloading" :disabled="cancelloading"-->


                    </template>
                </invoices-table-listing>
            </div>
        </div>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {
    getLastFriday,
    transformDateForFirstMomentOfDay,
    transformDateForLastMomentOfDay
} from "../../../Common/vue/helpers/date";

export default {
    name: "invoices-listing",
    props: {
        authType: {
            type: String,
            default: 'coordinator'
        }
    },
    data() {
        return {
            tableHeader: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Partner name', value: 'partner_name'},
                {text: 'Company name', value: 'company_name'},
                {text: 'Date', value: 'date'},
                {text: 'Factura', value: 'invoiceDownloadLink'},
                {text: 'Factura cu observatii', value: 'invoiceWithObservations'},
                // {text: 'Contract', value: 'contractDownloadLink'},
                {text: 'Semnare', value: 'sendToSigningLink'},
                // {text: 'Inregistreaza in contabilitate', value: 'sendToContabLink'},
                {text: 'Actions', value: 'actions'},
            ],

            sort: {
                sortBy: 'invoice_id',
                sortDirection: 'desc'
            },
            filters: {
                statusPdf: {
                    type: 'selectDropdown',
                    label: 'Generated PDF',
                    name: 'statusPdf',
                    value: null,
                    options: [
                        {value: null, text: 'Selecteaza o optiune', disabled: true},
                        {value: 1, text: 'Toate'},
                        {value: 2, text: 'Pdf generat'},
                        {value: 3, text: 'PDF inca negenerat'},
                    ]
                },
                isContabSent: {
                    type: 'selectDropdown',
                    label: 'Inregistrat in contabilitate',
                    name: 'isContabSent',
                    value: null,
                    options: [
                        {value: null, text: 'Selecteaza o optiune', disabled: true},
                        {value: 1, text: 'Toate'},
                        {value: 2, text: 'Inregistrat'},
                        {value: 3, text: 'Neinregistrat'},
                    ]
                },
                company: {
                    type: 'selectDropdown',
                    label: 'Companie',
                    name: 'company',
                    value: null,
                    options: [
                        {value: null, text: 'Selecteaza o optiune', disabled: true},
                    ]
                },
                partner_id: {
                    type: 'selectDropdown',
                    label: 'Partener',
                    name: 'partner_id',
                    value: null,
                    options: [
                        {value: null, text: 'Selecteaza o optiune', disabled: true},
                    ]
                },
                issue_date: {
                    type: 'singleDatePickerVB',
                    label: 'Data emiterii',
                    name: 'issue_date',
                    value: null,
                    options: []
                }
            },
        }
    },
    computed: {
        ...mapGetters('coordinators', ['invoiceListingData', 'invoiceTotal']),
        ...mapGetters('partners', ['partners', 'partnerForFilterSelect']),
        ...mapGetters('companies', ['companies', 'companyForFilterSelect']),
        show: () => true,

        orderSelectModel: () => {
            return {
                name: 'sortare',
                label: 'Sortare',
                options: [
                    {value: null, text: 'Please select an option', disabled: true},
                    {value: {sortBy: 'invoice_id', sortDirection: 'asc'}, text: 'Id crescator'},
                    {value: {sortBy: 'invoice_id', sortDirection: 'desc'}, text: 'Id descrescator'},
                    {value: {sortBy: 'partner_id', sortDirection: 'asc'}, text: 'Partener crescator'},
                    {value: {sortBy: 'partner_id', sortDirection: 'desc'}, text: 'Partener descrescator'},
                    {value: {sortBy: 'company', sortDirection: 'asc'}, text: 'Companie crescator'},
                    {value: {sortBy: 'company', sortDirection: 'desc'}, text: 'Companie descrescator'},
                    {value: {sortBy: 'created_at', sortDirection: 'asc'}, text: 'Data crescator'},
                    {value: {sortBy: 'created_at', sortDirection: 'desc'}, text: 'Data descrescator'},
                ],
                value: {sortBy: 'invoice_id', sortDirection: 'desc'}
            };
        },
        filterDates: () => {
            let date = new Date();
            return {
                startDate: transformDateForFirstMomentOfDay(getLastFriday()),
                endDate: transformDateForLastMomentOfDay(date)
            }
        },
        computedHeaders() {
            let computerHeaders = this.tableHeader.filter(header => this.authType === 'admin'
                || header.value !== 'sendToSigningLink')

            if (this.authType === 'admin') {
                // change start to 8 when contract column will be reintroduced
                computerHeaders.splice(7, 0,
                    {
                        text: 'Primit in contabilitate',
                        value: 'sendToContabLink'
                    });
                computerHeaders.splice(1, 0,
                    {
                        text: "Manager de proiect responsabil",
                        value: 'coordinator_name'
                    });
                computerHeaders.splice(10, 0,
                    {
                        text: "Admin Actions",
                        value: 'adminActions'
                    });
            }
            // change start to 9 when contract column will be reintroduced
            computerHeaders.splice(8, 0,
                {
                    text: "Alte informatii",
                    value: 'observations'
                });
            return computerHeaders;
        }
    },
    mounted() {

        this.getFiltersData();

        // this.getInvoiceListingData({
        //     filters: this.filters,
        //     sort: this.sort
        // });
    },

    methods: {
        ...mapActions('coordinators', ['getInvoiceListingData']),
        ...mapActions('partners', ['getPartnersForCoordinators']),
        ...mapActions('companies', ['getCompaniesForCoordinators']),
        getFiltersData: async function () {
            Promise.all([
                await this.getPartnersForCoordinators(),
                await this.getCompaniesForCoordinators()
            ]).then(() => {
                this.filters.company.options.push(...this.companyForFilterSelect);
                this.filters.partner_id.options.push(...this.partnerForFilterSelect);
            })
        },
        getFilterDates: function (filterDates) {
            this.filterDates.startDate = filterDates.startDate;
            this.filterDates.endDate = filterDates.endDate;
            this.fetchData();
        },
        getOrderSelectOption: function () {
            this.sort.sortBy = (this.orderSelectModel.value != null)
                ? this.orderSelectModel.value.sortBy : this.sort.sortBy;

            this.sort.sortDirection = (this.orderSelectModel.value != null)
                ? this.orderSelectModel.value.sortDirection : this.sort.sortDirection;

            this.fetchData();
        },
        getFilters: function () {
            this.getOrderSelectOption()
        },
        prepareFiltersForServer: function () {
            let filters = {};
            let filterKeys = Object.keys(this.filters);

            filterKeys.forEach(filterKey => {
                filters[this.filters[filterKey].name] = this.filters[filterKey].value;
            });

            return {
                ...filters,
                sortBy: this.sort.sortBy,
                sortDirection: this.sort.sortDirection,
                startDate: this.filterDates.startDate,
                endDate: this.filterDates.endDate,
            };
        },
        fetchData: function () {

            let data = this.prepareFiltersForServer();
            this.getInvoiceListingData(data);
        },
        generateContract: function (x) {
            let elementId = x.target
                .parentElement.parentElement
                .querySelector('[name="id"]').value;
            window.location.href =
                route(this.authType + '.contract.show-view-set-contract-details')
                + elementId;
        },
        generateInvoiceWithObservations: function (x) {
            let elementId = x.target
                .parentElement.parentElement
                .querySelector('[name="id"]').value;
            window.location.href =
                route(this.authType + '.contract.show-view-set-invoice-details')
                + elementId;
        },
    }
}
</script>

<style scoped>

</style>
