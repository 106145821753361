import Vue from "vue";

//Helper for front-end
// Vue.component(
//     "test-store",
//     require("./Pages/viewList").default
// );

// Vue.component(
//     "custom-input",
//     require("./Helpers/input").default
// );
// Vue.component(
//     "custom-input2",
//     require("./Helpers/input2").default
// );

Vue.component(
    "invoices-listing",
    require("../../Invoices/vue/Pages/invoices-listing").default
);

Vue.component(
    "upload-invoice",
    require("./Pages/uploadInvoice").default
);

Vue.component(
    "custom-search-select",
    require("./components/search-select").default,
);

Vue.component(
    "custom-upload-files",
    require("./components/upload-files").default,
);
Vue.component(
    "invoices-table-listing",
    require("./components/invoicesTableListing").default,
);

Vue.component(
    "set-invoice-details",
    require("./Pages/set-invoice-details").default
);


