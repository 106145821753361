<template>
    <!--//TODO: Actions on table-->
    <v-app>
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-1"
            :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'fas fa-backward',
                lastIcon: 'fas fa-forward',
                prevIcon: 'fas fa-arrow-left',
                nextIcon: 'fas fa-arrow-right'
            }"
        >

            <template v-slot:item.sendToContabLink="{ item }">
                <template v-if="!item.isContabSent">
                    <a href="javascript:void(0)"
                       @click="openConfirmationToSendInvoiceToContabModal(item.sendToContabLink)">
                        Inregistreaza
                    </a>
                </template>
                <template v-else>
                    Inregistrat {{ item.contabSentDate }} de {{ item.contab_sent_by_username }}
                </template>
            </template>

            <template v-slot:item.sendToSigningLink="{ item }">
                <template v-if="!item.isSigningSent">
                    <a href="javascript:void(0)"
                       @click="openConfirmationToSendSignedContractModal(item.sendToSigningLink)">
                        Semneaza
                    </a>
                </template>
                <template v-else>
                    {{ item.signedDate }} de {{ item.username }}
                </template>
            </template>

            <template v-slot:item.invoiceWithObservations="{ item }">
                <template v-if="item.hasPDFGenerated">
                    Observatii generate
                </template>
                <template v-else>
                    Negenerat
                </template>
            </template>

            <template v-slot:item.invoiceDownloadLink="{ item }">
                <a :href="item.invoiceDownloadLink">
                    Descarca
                </a>
                <a :href="item.invoiceDownloadLink + '/open'" target="_blank">
                    Vizualizeaza
                </a>
            </template>

            <template v-slot:item.contractDownloadLink="{ item }">
                <template v-if="item.hasContractGenerated">
                    <a :href="item.contractDownloadLink">
                        Descarca
                    </a>
                </template>
                <template v-else>
                    Negenerat
                </template>
            </template>

            <template v-slot:item.actions="{ item }">
                <input type="hidden" :value="item.id" name="id" id="id">
                <slot name="action-buttons"></slot>
                <v-btn color="error"
                       v-on:click="openConfirmationToSendCancelIndividualInvoiceModal($event)"
                       v-if="authType === 'admin'"
                >
                    Respinge
                </v-btn>
            </template>
            <template v-slot:item.adminActions="{ item }">
                <template v-if="!item.invoiceContractFrontName">
                    <div>
                        <!-- 1. Create the button that will be clicked to select a file -->
                        <input type="hidden" :value="item.id" name="invoiceId" id="invoiceId">
                        <v-btn
                            color="primary"
                            rounded
                            dark
                            :loading="isSelectingFile"
                            @click="() => handleFileImport(item.id)"
                        >
                            Adauga contract
                        </v-btn>

                        <!-- Create a File Input that will be hidden but triggered with JavaScript -->
                        <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            @change="(event) => onFileChanged(event, item.id)"
                            :id="'uploadContract' + item.id"
                        >
                    </div>
                </template>
                <template v-else>
                    Factura contine contractul
                    <a :href="item.invoiceContractLink" target="_blank">
                        {{ item.invoiceContractFrontName }}
                    </a>
                </template>
            </template>
        </v-data-table>
        <v-dialog
            v-model="showSendInvoiceToContabConfirmationDialog"
            persistent
            max-width="290"

        >
            <v-card>
                <v-card-title class="text-h5" style="word-break: break-word;">
                    Confirmare primire
                </v-card-title>
                <v-card-text>
                    A fost primit in contabilitate acest document?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="clickSendInvoiceToContabConfirmationDialog(false)"
                    >
                        Nu
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="clickSendInvoiceToContabConfirmationDialog(true)"
                    >
                        Da
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showSendSignedContractConfirmationDialog"
            persistent
            max-width="290"

        >
            <v-card>
                <v-card-title class="text-h5" style="word-break: break-word;">
                    Confirmare Semnat
                </v-card-title>
                <v-card-text>
                    Sunteti sigur ca a fost semnat?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="clickSendSignedContractConfirmationDialog(false)"
                    >
                        Nu
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="clickSendSignedContractConfirmationDialog(true)"
                    >
                        Da
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="showSendCancelIndividualInvoiceConfirmationDialog"
            persistent
            max-width="290"

        >
            <v-card>
                <v-card-title class="text-h5" style="word-break: break-word;">
                    Confirmare Respingere factura
                </v-card-title>
                <v-card-text>
                    Vreti sa respingeti aceasta factura?
                </v-card-text>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                    <v-text-field
                        label="Motivul respingerii"
                        placeholder="ex: Date gresite"
                        color="green"
                        v-model="cancel_reason"
                    ></v-text-field>
                </v-col>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="clickCancelIndividualInvoiceConfirmationDialog(false)"
                    >
                        Nu
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="clickCancelIndividualInvoiceConfirmationDialog(true)"
                    >
                        Da
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>

import {mapActions} from "vuex";

export default {
    props: {
        headers: {
            default: [],
        },
        authType: {
            default: 'coordinator'
        },
        getDataPayload: {
            default: [],
        },
        total: {
            default: 0
        },
        getData: {},
        items: [],
        itemsPerPage: {default: 10},
    },
    data() {
        return {
            isSelectingFile: false,
            selectedFile: null,
            loading: true,
            options: {
                itemsPerPage: this.itemsPerPage
            },
            showSendInvoiceToContabConfirmationDialog: false,
            sendInvoiceToContabConfirmationLink: "",
            showSendSignedContractConfirmationDialog: false,
            sendSignedContractConfirmationLink: "",
            showSendCancelIndividualInvoiceConfirmationDialog: false,
            sendCancelIndividualInvoiceConfirmationId: "",
            cancel_reason: "",
        }
    },

    watch: {
        options: {
            handler() {
                this.getDataFromApi(this.getDataPayload)
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions('invoices', ['sendToSigning', 'sendToContab', 'addContract']),
        ...mapActions('invoices', ['cancelInvoice']),

        handleFileImport(rowInvoiceFileInputId) {
            this.isSelectingFile = true;

            // After obtaining the focus when closing the FilePicker, return the button state to normal
            window.addEventListener('focus', () => {
                this.isSelectingFile = false
            }, {once: true});

            // Trigger click on the FileInput
            document.getElementById('uploadContract' + rowInvoiceFileInputId).click();
        },
        onFileChanged(e, invoiceId) {
            this.selectedFile = e.target.files[0];

            // Do whatever you need with the file, like reading it with FileReader
            let formData = new FormData();
            formData.append('file', this.selectedFile);
            formData.append('invoiceId', invoiceId);

            this.addContract(formData);
        },

        getDataFromApi() {
            this.loading = true
            const {sortBy, sortDesc, page, itemsPerPage} = this.options

            this.getData({
                    ...this.getDataPayload,
                    page: page,
                    perPage: itemsPerPage
                }
            ).then(data => {
                this.loading = false
            })
        },

        sendInvoiceToSigning(signingLink) {
            this.sendToSigning(signingLink);
            this.getDataFromApi(this.getDataPayload)
        },
        clickSendSignedContractConfirmationDialog(agree) {
            if (agree) {
                this.sendInvoiceToSigning(this.sendSignedContractConfirmationLink);
            }
            this.sendSignedContractConfirmationLink = "";
            this.showSendSignedContractConfirmationDialog = false;
        },
        openConfirmationToSendSignedContractModal(contabLink) {
            this.sendSignedContractConfirmationLink = contabLink;
            this.showSendSignedContractConfirmationDialog = true;
        },

        clickSendInvoiceToContabConfirmationDialog(agree) {
            if (agree) {
                this.sendInvoiceToContab(this.sendInvoiceToContabConfirmationLink);
            }
            this.sendInvoiceToContabConfirmationLink = "";
            this.showSendInvoiceToContabConfirmationDialog = false;
        },
        openConfirmationToSendInvoiceToContabModal(contabLink) {
            this.sendInvoiceToContabConfirmationLink = contabLink;
            this.showSendInvoiceToContabConfirmationDialog = true;
        },
        sendInvoiceToContab(contabLink) {
            this.sendToContab(contabLink);
            this.getDataFromApi(this.getDataPayload)
        },


        clickCancelIndividualInvoiceConfirmationDialog(agree) {
            if (agree) {
                console.log(this.cancel_reason);
                this.cancelIndividualInvoice(this.sendCancelIndividualInvoiceConfirmationId, this.cancel_reason);
            }
            this.sendCancelIndividualInvoiceConfirmationId = "";
            this.showSendCancelIndividualInvoiceConfirmationDialog = false;
        },
        openConfirmationToSendCancelIndividualInvoiceModal(event) {
            this.sendCancelIndividualInvoiceConfirmationId = event.target
                .parentElement.parentElement
                .querySelector('[name="id"]').value;
            this.showSendCancelIndividualInvoiceConfirmationDialog = true;
        },
        cancelIndividualInvoice: function (id, reason) {
            const requestData = {id, reason};
            this.cancelInvoice(requestData);
            this.getDataFromApi(this.getDataPayload);
        }
    },
}
</script>
