<template>
    <div>
        <b-form-group
            :label="showTitle?(filter.label):''"
            :class="groupClasses"
        >
            <div class="input-design">
                {{ filter.label }}: {{ selectedModel.text }}
                <i class="fas fa-times"
                   style="padding-left: 0.25rem;cursor: pointer;"
                   @click="cancelFilter"
                ></i>
            </div>

        </b-form-group>
    </div>
</template>

<script>
export default {
    props: {
        filter: {
            type: Object
        },
        showTitle: {
            default: false,
            type: Boolean
        },
        groupClasses: {
            type: String,
            default: ""
        },
        emitMethod: {}
    },
    computed: {
        selectedModel: function () {
            if (this.filter.type !== 'selectDropdown') {
                return {
                    'value': this.filter.value,
                    'text': this.filter.value
                }
            }
            return this.filter.options.filter(option => {
                return option.value === this.filter.value
            })[0];
        }
    },
    methods: {
        cancelFilter: function () {
            this.filter.value = null;
            this.sendData();
        },
        sendData: function () {
            this.$emit(this.emitMethod);
        },
    }
}
</script>

