const getData = async () => {
    try {
        let response = await axios({
            method: 'get',
            url: route('partner.profile.get-data')
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}

const saveData = async (data) => {
    // const {profile, status} = data;
    // de trimis status catre BE pt validare
    try {
        let response = await axios({
            method: 'patch',
            url: route('partner.profile.store-edit'),
            data: data.profile
        });
        return response.data;
    } catch (error) {
        throw error;
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}


export default {
    getData,
    saveData,
}
