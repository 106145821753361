<template>
    <v-container>
        <v-row>
            <v-col>
                <h3>
                    <slot name="title">
                        Add/Edit Administrator
                    </slot>
                </h3>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="8">

                <v-card class="mt-5">
                    <v-card-text>
                        <v-text-field
                            ref="name"
                            v-model="form.name"
                            :rules="[() => !!form.name || 'Acest camp este obligatoriu']"
                            :error-messages="adminsErrors.errors.name||[]"
                            label="Full Name"
                            required
                            background-color="red lighten-2"
                            @update:error="updateError()"
                        ></v-text-field>
                        <v-text-field
                            ref="email"
                            v-model="form.email"
                            :rules="[
                                ()=> !!form.email || 'Email is required',
                                ()=>/.+@.+/.test(form.email) || 'E-mail must be valid'
                            ]"
                            :error-messages="adminsErrors.errors.email||[]"
                            label="Email"
                            required
                            background-color="red lighten-2"
                            @update:error="updateError()"
                        ></v-text-field>
                        <v-text-field
                            ref="password"
                            v-model="form.password"
                            :rules="[()=> !!form.password || 'Password is required']"
                            :error-messages="adminsErrors.errors.password||[]"
                            label="Parola"
                            required
                            background-color="red lighten-2"
                            @update:error="updateError()"
                            :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                            :type="showPassword ? 'text' : 'password'"
                            counter
                            @click:append="showPassword = !showPassword"
                        ></v-text-field>
                        <v-text-field
                            ref="password_confirmation"
                            v-model="form.password_confirmation"
                            :rules="[
                                (form.password === form.password_confirmation) || 'Password must match'
                                ]"
                            :error-messages="adminsErrors.errors.password||[]"
                            label="Confirmare parola"
                            required
                            background-color="red lighten-2"
                            @update:error="updateError()"
                            :append-icon="showConfirmationPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                            :type="showConfirmationPassword ? 'text' : 'password'"
                            counter
                            @click:append="showConfirmationPassword = !showConfirmationPassword"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="submit" class="btn-primary-custom-vuetify">Submit</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "addEditAdministrator",
    props: {
        admin: {},
        submitForm: {},
    },
    data() {
        return {
            password_confirmation: "",
            showPassword: false,
            showConfirmationPassword: false,
            formHasErrors: false,
            form: {
                name: this.admin.name,
                email: this.admin.email,
                password: this.admin.password,
                password_confirmation: this.admin.password_confirmation,
            }
        }
    },
    computed: {
        ...mapGetters('admins', ['adminsErrors']),
        errorMessages() {
            console.log(this.adminsErrors.messages);
            return this.adminsErrors.messages || [];
        },
    },
    methods: {
        ...mapActions('admins', ['getAdminsForAdmin']),
        updateError() {
            console.log('error');
        },
        submit() {
            this.formHasErrors = false

            Object.keys(this.form).forEach(f => {
                if (!this.$refs[f].validate()) {
                    this.formHasErrors = true;
                }
            })
            if (!this.formHasErrors) {
                this.submitForm(this.form);
            }
        },
    }
}
</script>

<style scoped>

</style>
