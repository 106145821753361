<template>
    <div>
        <b-button v-b-toggle.sidebar-1 variant="primary">Filtre</b-button>
        <b-sidebar id="sidebar-1" title="Filtre" shadow>
            <!--            <template #default="{ hide }">-->
            <div class="p-3">
                <nav class="mb-3">
                    <template v-for="filter in filters">
                        <select-dropdown
                            v-if="filter.type==='selectDropdown'"
                            show-title="true"
                            :select-model="filter"
                            emit-method="get-filter-value"
                            @get-filter-value="getFilterValue"
                        ></select-dropdown>
                        <form-input
                            v-else-if="filter.type==='input'"
                            show-title="true"
                            :input-model="filter"
                            emit-method="get-filter-value"
                            @get-filter-value="getFilterValue"
                        ></form-input>
                        <single-date-picker-v-b
                            v-else-if="filter.type==='singleDatePickerVB'"
                            :picker-model="filter"
                            emit-method="get-filter-value"
                            @get-filter-value="getFilterValue"
                            :label-name="'Data emiterii'"
                        />
                    </template>

                </nav>
            </div>
            <!--            </template>-->
        </b-sidebar>
    </div>
</template>

<script>
import SingleDatePickerVB from "../input/singleDatePickerVB";
export default {
    components: {SingleDatePickerVB},
    props: ['emitMethod', 'filters'],
    methods: {
        sendData: function () {
            this.$emit(this.emitMethod);
        },
        getFilterValue: function () {
            // this.filters[data.filterName].value = data.filterValue;
            this.sendData();
        }
    },
}

</script>
