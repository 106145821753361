<template>
    <div id="vehicle-claims-view-list-container">
        <div class="container">
            <div class="row selected-filters-list no-margin-lr gap-12">
                <div>
                    <filters-menu
                        emit-method="get-filters"
                        @get-filters="getFilters"
                        :filters="filters"
                    />
                </div>
                <div>
                    <date-picker
                        @get-filter-dates="getFilterDates"
                        :filter-dates="this.filterDates"
                    />
                </div>
                <template v-for="filter in filters">
                    <div v-if="filter.value!=null">
                        <selected-filter
                            :filter="filter"
                            groupClasses="mr-1 mb-0 gap-12"
                            emit-method="get-filters"
                            @get-filters="getFilters"
                        />
                    </div>
                </template>

            </div>

            <hr class="row">

            <div class="row no-margin-lr gap-12">
                <div class="d-flex align-items-center">
                    <span>Ordoneaza dupa:</span>
                    <select-dropdown
                        :select-model="orderSelectModel"
                        emit-method="get-order-select-option"
                        @get-order-select-option="getOrderSelectOption"
                        groupClasses="ml-1 mb-0"
                    />
                </div>
                <div>
                    <view-list-comments-modal
                        :comment="comment"
                        @send-report="sendReport(
                            thisWeeksDates.startDate,
                            thisWeeksDates.endDate)"
                        open-modal-button-text="Trimitere raport saptamana curenta"
                    />
                </div>
                <div>
                    <b-button
                        v-on:click="setStatusToAllClaims('APPROVED')"
                        :disabled="!!this.areAllClaimsAnswered"
                    >
                        Aproba toate
                    </b-button>
                    <b-button
                        v-on:click="setStatusToAllClaims('REFUSED')"
                        :disabled="!!this.areAllClaimsAnswered"
                    >
                        Refuza toate
                    </b-button>
                </div>
            </div>
        </div>
        <div class="container ">
            <div class="row scrolling-component mt-4" ref='scrollComponent' id="card-scrolling-component">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 vehicle-list-container"
                     v-for="(vehicleClaim,indexVehicleClaim) in vehicleClaims"
                >
                    <div class="card shadow p-3 mb-5 bg-white rounded" style="width: 100%;">
                        <div class="card-body">
                            <div class="row group">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <strong>Zona:</strong>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            {{ vehicleClaim.zone }}
                                        </div>
                                    </div>
                                </div>

                                <div class="pr-3">
                                    <div class="row">
                                        <div class="col">
                                            <strong>Nr auto:</strong>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            {{ vehicleClaim.licensePlate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row group">
                                <div class="col">
                                    <strong>Data cerere: </strong>
                                    {{ vehicleClaim.requestDate |formatDate }}
                                </div>
                            </div>
                            <div class="row group">
                                <div class="col ">
                                    <div class="row">
                                        <div class="col">
                                            <strong>Persoana:</strong>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div>{{ vehicleClaim.requestPersonName }}</div>
                                            <div>
                                                <a :href="'tel:'+ vehicleClaim.requestPersonPhone">
                                                    <i class="fas fa-phone"></i>
                                                    {{ vehicleClaim.requestPersonPhone }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row group">
                                <div class="col">
                                    <strong>Tip utilizare: </strong>
                                    {{ vehicleClaim.utilizationType }}
                                </div>
                            </div>
                            <div class="group">
                                <div class="row">
                                    <div class="col">
                                        <strong>Perioada:</strong>
                                        {{ vehicleClaim.dateStart |formatDate }} -
                                        {{ vehicleClaim.dateEnd |formatDate }}
                                    </div>
                                </div>
                            </div>
                            <div class="group row">
                                <div class="col">
                                    <strong>Status:</strong>
                                    {{ vehicleClaim.status.description }}
                                </div>
                            </div>
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <div class="row justify-content-center" id="actions-to-answer">
                                    <div class="col-6" v-if="!vehicleClaim.status.isAnswered">
                                        <button type="button" class="btn btn-primary"
                                                v-on:click="sendAnswerToClaim(indexVehicleClaim,'APPROVED')"
                                        >
                                            Aproba
                                        </button>
                                    </div>
                                    <div class="col-6" v-if="!vehicleClaim.status.isAnswered">
                                        <button type="button" class="btn btn-danger"
                                                v-on:click="sendAnswerToClaim(indexVehicleClaim,'REFUSED')"
                                        >
                                            Refuza
                                        </button>
                                    </div>
                                    <!--                                    <div class="col-6" v-if="vehicleClaim.status.isAnswered">-->
                                    <!--                                        Status: {{ vehicleClaim.status.description }}-->
                                    <!--                                    </div>-->
                                    <div class="col-6" v-if="vehicleClaim.status.isAnswered">
                                        <button type="button" class="btn btn-danger"
                                                v-on:click="sendAnswerToClaim(indexVehicleClaim,'SENT_FOR_APPROVAL')"
                                        >
                                            Revocare Status
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 vehicle-list-container load-more"
                     v-if="this.currentPage !== this.lastPage"
                     @click="loadMore"
                >
                    <div class="card" style="width: 100%;height: 100%">
                        <div class="card-body" style="height: 100%">
                            <div class="d-flex flex-column align-items-center justify-content-center"
                                 style="height: 100%">
                                <i class="fas fa-sync-alt"></i>
                                <h3>Incarca mai mult</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import {
    getLastFriday,
    transformDateForCarbonDateParseFormat,
    transformDateForFirstMomentOfDay,
    transformDateForLastMomentOfDay
} from "../../Common/vue/helpers/date";


let infiniteScroll = require('vue-infinite-scroll');
export default {
    props: {
        route: {},
        getDataRoute: {},
        setAnswerToAllClaimsRoute: {},
        setAnswerRoute: {},
        getZoneListRoute: {},
        getStatusListRoute: {},
        sendReportRoute: {},
        checkIfUnansweredRoute: {},

    },

    data() {
        return {
            vehicleClaims: {},
            areAllClaimsAnswered: {},
            filters: {
                zone: {
                    type: 'selectDropdown',
                    label: 'Zone',
                    name: 'zone',
                    value: null,
                    options: [
                        {value: null, text: 'Selecteaza o optiune', disabled: true}
                    ]
                },
                status: {
                    type: 'selectDropdown',
                    label: 'Status',
                    name: 'status',
                    value: 3,
                    options: [
                        {value: null, text: 'Selecteaza o optiune', disabled: true}
                    ]
                },

            },
            currentPage: 1,
            lastPage: 1,
            scrollComponent: null,
            isBusy: false,
            sort: {
                sortBy: 'DataCerere',
                sortDirection: 'desc'
            },
            comment: {
                label: 'Comentarii',
                name: 'comment',
                value: "",
            },
        }
    }
    ,
    computed: {
        orderSelectModel: () => {
            return {
                name: 'sortare',
                label: 'Sortare',
                options: [
                    {value: null, text: 'Please select an option', disabled: true},
                    {value: {sortBy: 'Zona', sortDirection: 'asc'}, text: 'Zona asc'},
                    {value: {sortBy: 'Zona', sortDirection: 'desc'}, text: 'Zona desc'},
                    {value: {sortBy: 'Curier', sortDirection: 'asc'}, text: 'Curier asc'},
                    {value: {sortBy: 'Curier', sortDirection: 'desc'}, text: 'Curier desc'},
                    {value: {sortBy: 'DataCerere', sortDirection: 'asc'}, text: 'Data Cerere asc'},
                    {value: {sortBy: 'DataCerere', sortDirection: 'desc'}, text: 'Data Cerere desc'},
                ],
                value: {sortBy: 'DataCerere', sortDirection: 'desc'}
            };
        },
        filterDates: () => {
            let date = new Date();
            return {
                startDate: transformDateForFirstMomentOfDay(getLastFriday()),
                endDate: transformDateForLastMomentOfDay(date)
            }
        },
        thisWeeksDates: () => {
            let date = new Date();
            return {
                startDate: transformDateForFirstMomentOfDay(getLastFriday()),
                endDate: transformDateForLastMomentOfDay(date)
            }
        }
    },

    created() {
        window.addEventListener('scroll', this.handleScroll);
    },

    mounted() {
        this.fetchData();
        this.scrollComponent = this.$refs.scrollComponent;
        this.getFiltersData();
        this.getAreClaimsSentForApproval();
    },

    methods: {
        handleScroll() {
            let element = this.scrollComponent

            if (element.getBoundingClientRect().bottom < window.innerHeight) {
                this.loadMore()
            }
        },

        getFiltersForServer: function () {
            let filters = {};
            let filterKeys = Object.keys(this.filters);

            filterKeys.forEach(filterKey => {
                filters[this.filters[filterKey].name] = this.filters[filterKey].value;
            });

            return filters;
        },
        fetchData() {
            this.getAreClaimsSentForApproval();
            let data = {
                filterDateStart: transformDateForCarbonDateParseFormat(this.filterDates.startDate.toString()),
                filterDateEnd: transformDateForCarbonDateParseFormat(this.filterDates.endDate.toString()),
                sortBy: this.sort.sortBy,
                sortDirection: this.sort.sortDirection,
                ...this.getFiltersForServer()
            }

            axios.post(this.getDataRoute, data)
                .then((response) => {

                    this.vehicleClaims = response.data.data;
                    this.lastPage = response.data.lastPage;
                    this.currentPage = response.data.page;

                })
                .catch(function (error) {

                    if (error.response) {
                        console.error(error.response.data);
                        console.error(error.response.status);
                        console.error(error.response.headers);
                    }
                })
        },
        getAreClaimsSentForApproval() {

            let data = {
                filterDateStart: transformDateForCarbonDateParseFormat(this.filterDates.startDate.toString()),
                filterDateEnd: transformDateForCarbonDateParseFormat(this.filterDates.endDate.toString()),
                ...this.getFiltersForServer()
            }

            axios.post(this.checkIfUnansweredRoute, data)
                .then((response) => {
                    this.areAllClaimsAnswered = !response.data?.claimsUnansweredCount;
                    // console.log(response.data?.claimsUnansweredCount);
                    // this.areAllClaimsAnswered
                })
                .catch(function (error) {
                    this.areAllClaimsAnswered = false;
                    if (error.response) {
                        console.error(error.response.data);
                        console.error(error.response.status);
                        console.error(error.response.headers);
                        this.vehicleClaims = {};
                    }
                })
        },
        fetchMoreData() {
            if (this.currentPage === this.lastPage) {
                return;
            }

            let data = {
                filterDateStart: transformDateForCarbonDateParseFormat(this.filterDates.startDate.toString()),
                filterDateEnd: transformDateForCarbonDateParseFormat(this.filterDates.endDate.toString()),
                sortBy: this.sort.sortBy,
                sortDirection: this.sort.sortDirection,
                page: this.currentPage + 1,
                ...this.getFiltersForServer()
            }

            axios.post(this.getDataRoute, data)
                .then((response) => {
                    this.vehicleClaims = this.vehicleClaims.concat(response.data.data);
                    this.lastPage = response.data.lastPage;
                    this.currentPage = response.data.page;
                })
                .catch(function (error) {

                    if (error.response) {
                        console.error(error.response.data);
                        console.error(error.response.status);
                        console.error(error.response.headers);
                        this.vehicleClaims = {};
                    }
                })
        },

        loadMore: function () {
            setTimeout(() => {
                if (!this.isBusy) {
                    this.isBusy = true;
                    this.fetchMoreData();
                }
            }, 1000);
            this.isBusy = false;
        },

        sendAnswerToClaim: function (indexVehicleClaim, answerType) {
            axios.post(this.setAnswerRoute, {
                vehicleClaimId: this.vehicleClaims[indexVehicleClaim].id,
                answerType: answerType
            })
                .then((response) => {
                    this.vehicleClaims[indexVehicleClaim].status = response.data;
                    this.getAreClaimsSentForApproval();
                })
                .catch(function (error) {
                    if (error.response) {
                        console.error(error.response.data);
                        console.error(error.response.status);
                        console.error(error.response.headers);
                    }
                })
        },
        setStatusToAllClaims(answerType) {
            let data = {
                filterDateStart: transformDateForCarbonDateParseFormat(this.filterDates.startDate.toString()),
                filterDateEnd: transformDateForCarbonDateParseFormat(this.filterDates.endDate.toString()),
                answerType: answerType,
                ...this.getFiltersForServer()
            }
            axios.post(this.setAnswerToAllClaimsRoute, data)
                .then((response) => {
                    if (response.data?.success === true) {
                        this.fetchData();
                        flash(response.data.message, 'success');
                    } else {
                        flash(response.data.message, 'warning');
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        console.error(error.response.data);
                        console.error(error.response.status);
                        console.error(error.response.headers);
                    }
                });
        },
        getFilterDates: function (filterDates) {

            this.filterDates.startDate = filterDates.startDate;
            this.filterDates.endDate = filterDates.endDate;
            this.fetchData();


        },
        getOrderSelectOption: function () {
            this.sort.sortBy = (this.orderSelectModel.value != null)
                ? this.orderSelectModel.value.sortBy : this.sort.sortBy;

            this.sort.sortDirection = (this.orderSelectModel.value != null)
                ? this.orderSelectModel.value.sortDirection : this.sort.sortDirection;

            this.fetchData();
        },
        getFilters: function () {
            this.getOrderSelectOption()
            this.fetchData()
        },
        async getZonesList() {

            try {
                let zones = (await axios({
                    method: 'get',
                    url: this.getZoneListRoute
                })).data;

                let preparedZones = zones.map(zone => ({
                    value: zone.zona,
                    text: zone.zona,
                }));

                this.filters.zone.options.push(...preparedZones);
            } catch (error) {
                if (error.response) {
                    console.error(error.response.data);
                    console.error(error.response.status);
                    console.error(error.response.headers);
                }
            }
        },
        async getStatusesList() {

            try {
                let statuses = (await axios({
                    method: 'get',
                    url: this.getStatusListRoute
                })).data;

                let preparedStatuses = statuses.map(status => ({
                    value: status.StatusID,
                    text: status.Descriere,
                }));

                this.filters.status.options.push(...preparedStatuses);
            } catch (error) {
                if (error.response) {
                    console.error(error.response.data);
                    console.error(error.response.status);
                    console.error(error.response.headers);
                }
            }
        },
        getFiltersData: function () {
            this.getZonesList();
            this.getStatusesList();
        },
        async sendReport(
            startDate = this.filterDates.startDate.toString(),
            endDate = this.filterDates.endDate.toString()) {
            try {
                let response = (await axios({
                    method: 'post',
                    url: this.sendReportRoute,
                    data: {
                        filterDateStart: transformDateForCarbonDateParseFormat(startDate),
                        filterDateEnd: transformDateForCarbonDateParseFormat(endDate),
                        comment: this.comment.value,
                    }
                }));

                if (response.data.success === true) {
                    flash(response.data.message, 'success');
                } else {
                    if (!!response.data.message) {
                        flash(response.data.message, 'warning');
                    } else {
                        flash('A aparut o eroare', 'warning');
                    }
                }
            } catch (error) {
                if (error.response) {
                    console.error(error.response.data);
                    console.error(error.response.status);
                    console.error(error.response.headers);
                }
            }
        },
    },
}
</script>
