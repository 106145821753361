import coordinatorService from '../services/coordinator';

const state = {
  coordinator: {},
  coordinators: [],
  selectedOption: {},
  invoices: [],
  coordinatorsErrors: {message: '', errors: []},
  datePickerData: '',
};

const eventType = {
  getCoordinatorData: 'getCoordinatorData',
  saveData: 'saveData',
  getCoordinatorDataSuccess: 'getCoordinatorDataSuccess',
  getCoordinatorsSuccess: 'getCoordinatorsSuccess',
  getCoordinatorDataError: 'getCoordinatorDataError',
  getCoordinatorsDataError: 'getCoordinatorsDataError',
  createCoordinatorSuccess: 'createCoordinatorSuccess',
  setSelectedOption: 'setSelectedOption',
  getInvoiceListingData: 'getInvoiceListingData',
  getInvoiceListingDataError: 'getInvoiceListingDataError',
  setDatePickerData: 'setDatePickerData',
};

const getters = {
  coordinator: () => {
    return state.coordinator;
  },
  // selectCoordinator: () => {
  //     return [Object.fromEntries(
  //         Object.entries(state.coordinator).slice(0, 2)
  //     )];
  // },
  selectCoordinators: () => {
    return state.coordinators.map(coordinator => ({
      id: coordinator.id,
      value: coordinator.name,
    }));
  },
  coordinators: () => {
    return state.coordinators;
  },
  getterSelectedOption: () => {
    return state.selectedOption;
  },
  invoiceListingData: () => {
    return state.invoices.data;
  },
  invoiceTotal: () => {
    return state.invoices.total;
  },
  coordinatorErrors: () => {
    return state.coordinatorsErrors;
  },
  getterDatePickerData: () => {
    return state.datePickerData;
  },
};

const mutations = {
  [eventType.getCoordinatorDataSuccess](stateData, coordinatorData) {
    state.coordinator = coordinatorData;
  },
  [eventType.createCoordinatorSuccess](stateData, coordinatorData) {
    state.coordinator = coordinatorData;
  },
  [eventType.getCoordinatorsDataError](stateData, getCoordinatorsDataError) {
    state.coordinatorsErrors = getCoordinatorsDataError;
  },
  [eventType.getCoordinatorsSuccess](stateData, coordinators) {
    state.coordinators = coordinators;
    state.coordinators.data = coordinators?.data?.map(coordinator => {
          coordinator.name = coordinator?.name.toUpperCase();
          return coordinator;
        },
    );
  },
  [eventType.setSelectedOption](stateData, selectData) {
    state.selectedOption = selectData;
  },
  [eventType.setDatePickerData](stateData, datePickerData) {
    state.datePickerData = datePickerData;
  },
  [eventType.getInvoiceListingData](stateData, invoiceData) {
    state.invoices = invoiceData;
    state.invoices.data = invoiceData?.data?.map(invoice => {
          invoice.coordinator_name = invoice?.coordinator_name.toUpperCase();
          invoice.company_name = invoice?.company_name.toUpperCase();
          invoice.partner_name = invoice?.partner_name.toUpperCase();
          return invoice;
        },
    );
  },
};

const actions = {
  getCoordinatorData({commit}) {
    coordinatorService.getCoordinatorData().then(
        (coordinatorData) => {
          commit(eventType.getCoordinatorDataSuccess, coordinatorData);
        },
        (error) => {
          commit(eventType.getCoordinatorDataError, error);
        },
    );
  },
  
  setSelectData({commit}, selectData) {
    commit(eventType.setSelectedOption, selectData);
  },
  
  setDatePickerData({commit}, datePickerData) {
    commit(eventType.setDatePickerData, datePickerData);
  },
  
  getCoordinatorsForPartner({commit}, options) {
    coordinatorService.getCoordinatorsForPartner(options).then(
        (coordinators) => {
          commit(eventType.getCoordinatorsSuccess, coordinators);
        },
        (error) => {
          commit(eventType.getCoordinatorDataError, error);
        },
    );
  },
  
  getCoordinatorsForAdmin({commit}, options) {
    coordinatorService.getCoordinatorsForAdmin(options).then(
        (coordinators) => {
          commit(eventType.getCoordinatorsSuccess, coordinators);
        },
        (error) => {
          commit(eventType.getCoordinatorDataError, error);
        },
    );
  },
  
  createNewCoordinator({commit}, data) {
    coordinatorService.createNew(data).then(
        (coordinator) => {
          commit(eventType.createCoordinatorSuccess, coordinator);
          window.location.replace(route('admin.pages.coordinators'));
        },
        (error) => {
          commit(eventType.getCoordinatorsDataError, JSON.parse(error.message));
        },
    );
  },
  
  saveData({commit}, coordinator) {
    coordinatorService.saveData(coordinator).then(
        (coordinatorData) => {
          console.log(coordinatorData);
          commit(eventType.getCoordinatorDataSuccess, coordinatorData);
        },
        (error) => {
          commit(eventType.getCoordinatorDataError, error);
        },
    );
  },
  
  getInvoiceListingData({commit}, data) {
    coordinatorService.getInvoiceListingData(data).then(
        (invoiceData) => {
          commit(eventType.getInvoiceListingData, invoiceData);
        },
        (error) => {
          commit(eventType.getInvoiceListingDataError, error);
        },
    );
  },
  deleteSoftCoordinator({commit}, coordinatorId) {
    coordinatorService.deleteSoftCoordinator(coordinatorId).then(
        () => {
          flash('Coordonator Sters', 'success');
          window.location.reload();
          return true;
        }, (error) => {
          flash('Eroare de server', 'warning');
          return false;
        });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
