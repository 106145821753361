<template>
    <!--//TODO: Actions on table-->
    <v-app>
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="1"
            :loading="loading"
            class="elevation-1"
        >
            <template v-slot:item.actions="{ item }">
                <input type="hidden" :value="item.id" name="id" id="id">
                <slot name="action-buttons"></slot>
            </template>
        </v-data-table>
    </v-app>
</template>

<script>

export default {
    props: {
        headers: {
            default: [],
        },
        getDataPayload: {
            default: [],
        },
        getData: {},
        items: [],
        itemsPerPage: {default: 10},
    },
    data() {
        return {
            totalDesserts: 0,
            desserts: [],
            loading: true,
            options: {
                itemsPerPage: this.itemsPerPage
            },
        }
    },
    watch: {
        options: {
            handler() {
                this.getDataFromApi(this.getDataPayload)
            },
            deep: true,
        },
    },
    methods: {
        getDataFromApi() {
            this.loading = true
            this.getData(this.getDataPayload).then(data => {
                // this.desserts = data.items
                // this.totalDesserts = data.total
                this.loading = false
            })
        },
        /**
         * In a real application this would be a call to fetch() or axios.get()
         */

    },
}
</script>
