<template>
    <b-container>
        <b-row>
            <b-col>
                <b-form
                    @submit.prevent="(e) => {e.preventDefault()}"
                    @submit="saveData({profile, status})"
                    @reset=""
                    v-if="show"
                >
                    <b-form-group
                        id="input-group-1"
                        label="Editare profil"
                        label-for="input-1"
                        description="We'll never share your email with anyone else."
                    >
                        <b-form-group id="input-group-2" :label="profile.isPartner === 1 ? 'Reprezentant legal:' : 'Nume utilizator:'" label-for="user-name">
                            <b-form-input
                                id="user-name"
                                v-model="profile.userName"
                                placeholder="Enter name"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="Email:" label-for="user-email">
                            <b-form-input
                                id="user-email"
                                v-model="profile.userEmail"
                                placeholder="Enter email"
                                disabled
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="Company name:" label-for="company-name">
                            <b-form-input
                                id="company-name"
                                v-model="profile.companyName"
                                placeholder="Enter company name"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="Company CUI:" label-for="company-cui">
                            <b-form-input
                                id="company-cui"
                                v-model="profile.companyCUI"
                                placeholder="Enter company CUI"
                                required
                            ></b-form-input>
                            <span>Numele companiilor nu mai trebuie sa conțină SC</span>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="Phone number:" label-for="phone-number">
                            <b-form-input
                                id="phone-number"
                                v-model="profile.phone_number"
                                placeholder="Enter phone number"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="Address:" label-for="address">
                            <b-form-input
                                id="address"
                                v-model="profile.address"
                                placeholder="Enter address"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="status"
                            name="checkbox-confirm-data"
                            :value="true"
                            :unchecked-value="false"
                            required
                        >
                            Confirm ca toate campurile sunt corecte
                        </b-form-checkbox>

                        <b-button type="submit" variant="primary">Submit</b-button>
                        <b-button type="reset" variant="danger">Reset</b-button>
                    </b-form-group>
                </b-form>
            </b-col>

        </b-row>
    </b-container>
</template>
<script>

import {mapActions, mapGetters} from "vuex";

export default {
    props: {},
    data() {
        return {
            status: false,
        };
    },
    computed: {
        //TODO: Se reseteaza desi nu ar trebui
        ...mapGetters('profile', ['profile']),
        show: () => true,
    },
    mounted() {
        this.getData();
    },

    methods: {
        ...mapActions('profile', ['getData', 'saveData']),
    }

}
</script>
