const impersonate = async (userId) => {
    try {
        let response = await axios({
            method: 'post',
            url: route('admin.impersonate'),
            data:{userId:userId}
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}


export default {
    impersonate,
}
