<template>
    <div>

        <date-range-picker
            ref="picker"
            v-model="dateRange"
            :ranges="rangesCustom"
            :autoApply="true"
            opens="right"
            @update="sendFilterDates"
        >

            <template #ranges="ranges">
                <div class="ranges">
                    <ul>
                        <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
                            <b>{{ name }}</b> <br/><small class="text-muted">{{ range[0]|formatDate }} -
                                                                             {{ range[1]|formatDate }}</small>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-slot:input="picker" style="min-width: 350px;display: none">
                {{ picker.startDate|formatDate }} - {{ picker.endDate | formatDate }}
            </template>
        </date-range-picker>

    </div>
</template>

<script>

import DateRangePicker from "vue2-daterange-picker";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import './style.css'

import {
    getCurrentMonthFirstDay,
    getLastMonthFirstDay,
    getLastMonthLastDay,
    getLastWeekMonday,
    getLastWeekSunday,
    getThisWeekMonday,
    transformDateForFirstMomentOfDay,
    transformDateForLastMomentOfDay
} from "../../../../helpers/date";

export default {
    name: "SlotsDemo",
    components: {DateRangePicker},
    props: ['filterDates'],
    data() {
        let startDate = this.filterDates.startDate;
        let endDate = this.filterDates.endDate;
        // let date = new Date();
        // // endDate.setDate(endDate.getDate() + 6)
        // let today = new Date();
        // let yesterday = new Date();
        // yesterday.setDate(today.getDate() - 1)
        // var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);


        let rangesCustom;

        rangesCustom = this.getRanges();

        return {
            dateRange: {startDate, endDate},
            rangesCustom
        }

    },
    filters: {
        dateCell(value) {
            let dt = new Date(value)

            return dt.getDate()
        },
        date(val) {
            return val ? val.toLocaleString() : ''
        }
    },
    methods: {
        getRanges: function () {
            let date = new Date();
            return {
                "Saptamana curenta": [
                    transformDateForFirstMomentOfDay(getThisWeekMonday()),
                    transformDateForLastMomentOfDay(date)
                ],
                "Saptamana trecuta": [
                    transformDateForFirstMomentOfDay(getLastWeekMonday()),
                    transformDateForLastMomentOfDay(getLastWeekSunday())
                ],
                "Luna curenta": [
                    transformDateForFirstMomentOfDay(getCurrentMonthFirstDay()),
                    transformDateForLastMomentOfDay(date)
                ],
                "Luna trecuta": [
                    transformDateForFirstMomentOfDay(getLastMonthFirstDay()),
                    transformDateForLastMomentOfDay(getLastMonthLastDay())

                ]
            };

        },
        sendFilterDates: function (filterDates) {
            this.$emit('get-filter-dates', filterDates);
        },


    }
}
</script>

<style scoped>

.slot {
    background-color: #aaa;
    padding: 0.5rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text-black {
    color: #000;
}

.vue-daterange-picker {
    min-width: 201px;
}

</style>
<style>

</style>
