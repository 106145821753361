import Vue from 'vue';
import Vuex from 'vuex';
import profile from "./profile";
import invoices from "./invoices";
import coordinators from "./coordinators";
import partners from "./partners";
import companies from "./companies";
import admins from "./admins";
import contracts from "./contracts";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        profile,
        invoices,
        coordinators,
        companies,
        partners,
        contracts,
        admins
    }
});
