import partnerService from "../services/partner"

const state = {
    partner: {},
    partners: [],
    partnersErrors: {message: '', errors: []},
};

const eventType = {
    getPartnerDataSuccess: "getPartnerDataSuccess",
    getPartnersSuccess: "getPartnersSuccess",
    getPartnerDataError: "getPartnerDataError",
    getPartnersDataError: "getPartnersDataError",
    createPartnerSuccess: "createPartnerSuccess"
}

const getters = {
    partner: () => {
        return state.partner;
    },
    selectPartner: () => {
        return [Object.fromEntries(
            Object.entries(state.partner).slice(0, 2)
        )];
    },
    partnerForFilterSelect: () => {
        return state.partners.map(partner => (
            {
                value: partner.id,
                text: partner.name
            }
        ))
    },
    partners: () => {
        return state.partners
    },
    partnersErrors: () => {
        return state.partnersErrors
    }
};

const mutations = {
    [eventType.getPartnerDataSuccess](stateData, partnerData) {
        state.partner = partnerData;
    },
    [eventType.createPartnerSuccess](stateData, partnerData) {
        state.partner = partnerData;
    },
    [eventType.getPartnersDataError](stateData, getPartnersDataError) {
        state.partnersErrors = getPartnersDataError;
    },
    [eventType.getPartnersSuccess](stateData, partners) {
        state.partners = partners;
        
        state.partners = partners?.map(partner => {
                partner.name = partner?.name.toUpperCase();
                return partner;
            },
        );
    }
};

const actions = {
    getPartnerData({commit}) {
        partnerService.getPartnerData()
            .then(
                (partnerData) => {
                    commit(eventType.getPartnerDataSuccess, partnerData);
                },
                (error) => {
                    commit(eventType.getPartnerDataError, error)
                }
            )
    },
    getPartnersForAdmin({commit}, options) {
        partnerService.getPartnersForAdmin(options)
            .then(
                (partners) => {
                    commit(eventType.getPartnersSuccess, partners);
                },
                (error) => {
                    commit(eventType.getPartnerDataError, error)
                }
            )
    },

    async getPartnersForCoordinators({commit}, options) {
        await partnerService.getPartnersForCoordinators(options)
            .then(
                (partners) => {
                    commit(eventType.getPartnersSuccess, partners);
                },
                (error) => {
                    commit(eventType.getPartnerDataError, error)
                }
            )
    },

    createNewPartner({commit}, data) {
        partnerService.createNew(data)
            .then(
                (partner) => {
                    commit(eventType.createPartnerSuccess, partner);
                    window.location.href = (route('admin.pages.partners'))
                },
                (error) => {
                    commit(eventType.getPartnersDataError, JSON.parse(error.message))
                }
            )
    },

    saveData({commit}, partner) {
        partnerService.saveData(partner)
            .then(
                (partnerData) => {
                    console.log(partnerData)
                    commit(eventType.getPartnerDataSuccess, partnerData);
                },
                (error) => {
                    commit(eventType.getPartnerDataError, error)
                }
            )
    },
    deleteSoftPartner({commit}, partnerId) {
        partnerService.deleteSoftPartner(partnerId).then(
            () => {
                flash('Partener Sters', 'success');
                window.location.reload();
                return true;
            }, (error) => {
                flash('Eroare de server', 'warning');
                return false;
            });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
