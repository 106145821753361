<template>
    <div>
        <b-button v-b-modal.modal-prevent-closing>{{ openModalButtonText }}</b-button>

        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Trimitere raport"
            @ok="handleOk"
        >
            <form-textarea
                show-title="true"
                :input-model="comment"
            ></form-textarea>
        </b-modal>
    </div>
</template>

<script>


export default {
    props: {
        comment: {
            type: Object,
            default: () => {
                return {
                    label: 'Label',
                    name: 'name',
                    value: "",
                };
            }
        },
        openModalButtonText: {
            default: () => {
                return "Trimitere raport"
            }
        }
    },
    data() {
        return {
            name: '',
            nameState: null,
            submittedNames: []
        }
    },
    methods: {
        resetModal() {
            this.name = ''
            this.nameState = null
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {

            this.$emit('send-report');
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        }
    }

}
</script>
