import moment from "moment";

export function getThisWeekMonday() {
    return getMondayFromAWeekDay(new Date())
}

export function getLastFriday() {
    // let sunday = getLastWeekSunday();
    let day = new Date();

    if (day.getDay() === 6) {
        day.setDate(day.getDate() - 1);

        return day;
    }
    if (day.getDay() === 0) {

        day.setDate(day.getDate() - 2);

        return day;
    }
    day = getLastWeekMonday();

    return new Date(new Date(day.setDate(day.getDate() + 4)));
}

export function getMondayFromAWeekDay(d) {

    d = new Date(d);

    let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

export function getSundayFromAWeekDay(d) {
    let monday = getMondayFromAWeekDay(d);
    let sunday = new Date();
    // sunday.setDate(monday.getDate() + 6)
    return new Date(sunday.setDate(monday.getDate() + 6));

}


export function getADayFromLastWeek() {
    let today = new Date();
    today.setDate(today.getDate() - 7);
    return today;
}

export function getLastWeekMonday() {
    return getMondayFromAWeekDay(getADayFromLastWeek());
}

export function getLastWeekSunday() {
    return new Date(getSundayFromAWeekDay(getLastWeekMonday()))
}

export function getCurrentMonthFirstDay() {
    let date = new Date()
    return new Date(date.getFullYear(), date.getMonth(), 1)
}

export function getLastMonthFirstDay() {
    let date = new Date()
    return new Date(date.getFullYear(), date.getMonth() - 1, 1);

}

export function getLastMonthLastDay() {
    let date = new Date()
    return new Date(date.getFullYear(), date.getMonth(), 0);
}

export function transformDateForFirstMomentOfDay(date) {
    // let x= new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    // console.log(date,x)
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
}

export function transformDateForLastMomentOfDay(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 998);
}

export function transformDateForCarbonDateParseFormat(date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss.SSS');
}
