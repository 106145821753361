<template>
    <add-edit-partner
        :partner={}
        :submitForm="createNewPartner"
    >
        <template v-slot:title>
            <span>
                Adauga Partener
            </span>
        </template>
    </add-edit-partner>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters('partners', ['partners'])
    },
    mounted() {
        // this.getCoordinatorsForAdmin({
        //     page: 1
        // })
    },
    methods: {
        ...mapActions('partners', ['createNewPartner']),
    }

}
</script>
