import invoiceService from "../services/invoice"

const state = {
    invoice: {},
    invoiceSuccess: false,
    uploadedFile: [],
    invoiceErrors: [],
    contractUploadErrors: [],
    contractUploadSuccess: false
};

const eventType = {
    getData: "getData",
    saveData: "saveData",
    getDataSuccess: "getDataSuccess",
    getInvoiceResponse: "getInvoiceResponse",
    getDataError: "getDataError",
    setUploadedFile: "setUploadedFile",
    getInvoiceDataError: "getInvoiceDataError",
    getContractUploadResponse : 'getContractUploadResponse',
    getContractUploadErrors: 'getContractUploadErrors'
}

const getters = {
    invoice: () => {
        return state.invoice;
    },
    invoiceUploadResponse: () => {
        return state.invoiceSuccess;
    },
    getterUploadedFile: () => {
        return state.uploadedFile;
    },
    invoicesErrors: () => {
        return state.invoiceErrors;
    }
};

const mutations = {
    [eventType.getDataSuccess](stateData, invoiceData) {
        state.invoice = invoiceData;
    },
    [eventType.getInvoiceResponse](stateData, invoiceResponse) {
        state.invoiceSuccess = invoiceResponse;
    },
    [eventType.getDataError](stateData, getDataError) {
        state.invoice = null
        console.log(getDataError);
    },
    [eventType.setUploadedFile](stateData, uploadedFileData) {
        state.uploadedFile = uploadedFileData;
    },
    [eventType.getInvoiceDataError](stateData, getInvoiceDataError) {
        state.invoiceErrors = getInvoiceDataError;
    },
    [eventType.getContractUploadResponse](stateData, getContractUploadResponse) {
        state.contractUploadSuccess = getContractUploadResponse;
    },
    [eventType.getContractUploadErrors](stateData, getContractUploadErrors) {
        state.contractUploadErrors = getContractUploadErrors;
    },
};

const actions = {
    getData({commit}) {
        invoiceService.getData()
            .then(
                (invoiceData) => {
                    commit(eventType.getDataSuccess, invoiceData);
                },
                (error) => {
                    commit(eventType.getDataError, error)
                }
            )
    },
    setUploadedFileData({commit}, uploadedFileData) {
        commit(eventType.setUploadedFile, uploadedFileData);
    },
    saveData({commit}, invoice) {
        invoiceService.saveData(invoice)
            .then(
                (invoiceData) => {
                    flash('Fisier urcat', 'success');
                    commit(eventType.getInvoiceResponse, invoiceData);
                    commit(eventType.getInvoiceDataError, false)
                },
                (error) => {
                    let errorsObject = JSON.parse(error.message);
                    flash(error.message, 'warning');
                    let errorsObjectMessages = Object.values(errorsObject);
                    let finalErrorMessages = [];

                    for (const [key] of errorsObjectMessages) {
                        finalErrorMessages.push(key);
                    }
                    commit(eventType.getInvoiceDataError, finalErrorMessages)
                }
            )
    },

    sendToSigning({commit}, signingLink) {
        invoiceService.sendToSigning(signingLink)
            .then(
                (invoiceData) => {
                    commit(eventType.getDataSuccess, invoiceData);
                },
                (error) => {
                    commit(eventType.getDataError, error)
                }
            )
    },

    sendToContab({commit}, contabLink) {
        invoiceService.sendToContab(contabLink)
            .then(
                (invoiceData) => {
                    commit(eventType.getDataSuccess, invoiceData);
                },
                (error) => {
                    commit(eventType.getDataError, error)
                }
            )
    },

    addContract({commit}, data) {
        invoiceService.addContract(data)
            .then(
                (data) => {
                    flash('Fisier urcat', 'success');
                    commit(eventType.getContractUploadResponse, data);
                    commit(eventType.getContractUploadErrors, false);
                    window.location.reload();
                },
                (error) => {
                    let errorsObject = JSON.parse(error.message);
                    flash(error.message, 'warning');
                    let errorsObjectMessages = Object.values(errorsObject);
                    let finalErrorMessages = [];

                    for (const [key] of errorsObjectMessages) {
                        finalErrorMessages.push(key);
                    }
                    commit(eventType.getContractUploadErrors, finalErrorMessages)
                }
            )
    },

    cancelInvoice({commit}, data) {
        invoiceService.cancelInvoice(data)
            .then(
                (data) => {
                    commit(eventType.getDataSuccess, data);
                },
                (error) => {
                    commit(eventType.getDataError, error)
                }
            )
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
