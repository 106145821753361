import Vue from "vue";

//Helper for front-end

// Vue.component(
//     "invoices-listing",
//     require("../../Invoices/vue/Pages/invoices-listing").default
// );

Vue.component(
    "set-contract-details",
    require("./Pages/set-contract-details").default
);
