<template>
    <add-edit-administrator
        :admin={}
        :submitForm="createNewAdmin"
    >
        <template v-slot:title>
            <span>
                Adauga Administrator
            </span>
        </template>
    </add-edit-administrator>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "add",
    data() {
        return {}
    },
    computed: {
        ...mapGetters('admins', ['admins'])
    },
    mounted() {
        // this.getCoordinatorsForAdmin({
        //     page: 1
        // })
    },
    methods: {
        ...mapActions('admins', ['createNewAdmin']),
    }
}
</script>

<style scoped>

</style>
