<template>
    <div class="alert" :class="'alert-'+type" role="alert" v-if="show">
        <div class="container">
            <div class="row justify-content-center">
                {{ body }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['message'],
    name: "flash",
    data() {
        return {
            show: false,
            body: '',
            type: 'success',
        }
    },
    created() {
        if (this.message) {
            this.flash(this.message)
        }
    },
    mounted() {
        window.events.$on('flash', (message) => this.flash(message))
    },
    methods: {
        flash(data) {
            this.type = (data.type) ? data.type : this.type;
            this.show = true;
            this.body = data.message;

            setTimeout(() => {
                this.hide();
            }, 5000);
        },
        hide() {
            this.show = false;
        }
    }
}
</script>

