<template>
    <v-col
        cols="12"
        sm="6"
        md="4"
    >
        <v-menu
            v-model="issueDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="issueDate"
                    :label="label"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    color="green lighten-1"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="issueDate"
                @input="issueDateMenu = false"
                color="green lighten-1"
                @change="onDatePickerChange"
            ></v-date-picker>
        </v-menu>
    </v-col>
</template>

<script>
export default {
    name: "singleDatePicker",
    props: {
        label: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            issueDate: '',
            issueDateMenu: false,
        }
    },
    methods: {
        onDatePickerChange(e) {
            this.$emit("datePickerValue", this.issueDate);
        }
    }
}
</script>

<style scoped>

</style>
