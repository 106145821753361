<template>
    <div>
        <b-form-group
            id="fieldset-1"
            :label="showTitle?(selectModel.label):''"
            :class="groupClasses+` capitalize`"
        >
            <b-form-select v-model="selectModel.value" :options="selectModel.options"
                           @change="sendSelectedData"
                           :name="selectModel.name"
            ></b-form-select>
        </b-form-group>
    </div>
</template>

<script>
export default {
    props: ['selectModel', 'emitMethod', 'showTitle', 'groupClasses'],
    data() {
        return {
            select: null
        }
    },

    methods: {
        sendSelectedData: function () {
            // const filterData = {
            //     filterName: this.selectModel.name,
            //     filterValue: this.selectModel.value,
            // };
            this.$emit(this.emitMethod);
        },
    },
}
</script>
