<template>
    <v-container>
        <v-row>
            <v-col>
                <h3>
                    <slot name="title">
                        Add/Edit Coordinator
                    </slot>
                </h3>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="8">
                <v-card class="mt-5">
                    <v-form
                        ref="form"
                        lazy-validation
                    >
                        <v-card-text>
                            <v-text-field
                                v-model="form.name"
                                :rules="[()=>!!form.name || 'Acest camp este obligatoriu']"
                                :error-messages="coordinatorErrors.errors.name||[]"
                                label="Full Name"
                                required
                                background-color="red lighten-2"
                                @update:error="updateError()"
                            ></v-text-field>
                            <v-text-field
                                v-model="form.email"
                                :rules="[
                                    ()=> !!form.email || 'Email is required',
                                    ()=>/.+@.+/.test(form.email) || 'E-mail must be valid']"
                                :error-messages="coordinatorErrors.errors.email||[]"
                                label="Email"
                                required
                                background-color="red lighten-2"
                                @update:error="updateError()"
                            ></v-text-field>
                            <v-text-field
                                v-model="form.password"
                                :rules="[()=>!!form.password || 'Parola este obligatorie']"
                                :error-messages="coordinatorErrors.errors.password||[]"
                                label="Parola"
                                required
                                background-color="red lighten-2"
                                @update:error="updateError()"
                                :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                                :type="showPassword ? 'text' : 'password'"
                                counter
                                @click:append="showPassword = !showPassword"
                            ></v-text-field>
                            <v-text-field
                                v-model="form.password_confirmation"
                                :rules="[()=>(form.password === form.password_confirmation) || 'Password must match']"
                                :error-messages="coordinatorErrors.errors.password||[]"
                                label="Confirmare parola"
                                required
                                background-color="red lighten-2"
                                @update:error="updateError()"
                                :append-icon="showConfirmationPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
                                :type="showConfirmationPassword ? 'text' : 'password'"
                                counter
                                @click:append="showConfirmationPassword = !showConfirmationPassword"
                            ></v-text-field>
                        </v-card-text>
                    </v-form>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="submit" class="btn-primary-custom-vuetify">Submit</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    props: {
        coordinator: {},
        submitForm: {},
    },
    data() {
        return {
            password_confirmation: "",
            showPassword: false,
            showConfirmationPassword: false,
            formHasErrors: false,
            form: {
                name: (!!this.coordinator.name) ? this.coordinator.name : "",
                email: (!!this.coordinator.email) ? this.coordinator.email : "",
                password: (!!this.coordinator.password) ? this.coordinator.password : "",
                password_confirmation: (!!this.coordinator.password_confirmation) ? this.coordinator.password_confirmation : "",
            },
        }
    },
    computed: {
        ...mapGetters('coordinators', ['coordinatorErrors']),
        ...mapGetters('partners', ['partnersErrors']),
        errorMessages() {
            return this.coordinatorsErrors.messages || [];
        },
    },
    methods: {
        ...mapActions('coordinators', ['getCoordinatorsForAdmin']),
        updateError() {
            console.log('error');
        },
        submit() {
            if (this.$refs.form.validate()) {
                this.submitForm(this.form);
            }
        },
    }

}
</script>
