window.route = require('./routes');
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import vuetify from './vuetify';
import 'vuetify/dist/vuetify.min.css'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import moment from 'moment'
import "../../app/Domains/Invoices/vue/Components.js";
import "../../app/Domains/Partners/vue/Components.js";
import "../../app/Domains/Coordinators/vue/Components.js";
import "../../app/Domains/Admins/vue/Components";
import "../../app/Domains/Common/vue/Components";
import 'es6-promise/auto'
import store from "./store/index";

let Vue = require('vue').default;


Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
    }
});

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

window.events = new Vue();

window.flash = function (message, type) {
    window.events.$emit('flash', {message, type});
}

/**
 * Common Components
 */
Vue.component('date-picker',
    require('../../app/Domains/Common/vue/views/components/input/datePicker/template.vue').default);
Vue.component('flash',
    require('../../app/Domains/Common/vue/views/components/flash.vue').default);


/**
 * Form Common Components
 */
Vue.component('select-dropdown',
    require('../../app/Domains/Common/vue/views/components/input/selectDropdown').default);
Vue.component('form-input',
    require('../../app/Domains/Common/vue/views/components/input/input').default);
Vue.component('form-textarea',
    require('../../app/Domains/Common/vue/views/components/input/textarea').default);
/**
 * Filter Common Components
 */
Vue.component('selected-filter',
    require('../../app/Domains/Common/vue/views/components/filters/selectedFilter').default);
Vue.component('filters-menu',
    require('../../app/Domains/Common/vue/views/components/filters/filtersMenu').default);

/**
 * Vehicle Claim Domain
 */
Vue.component('vehicle-claim-filters-menu',
    require('../../app/Domains/VehicleClaim/vue/components/filtersMenu').default);
Vue.component('vehicle-claim-viewlist',
    require('./../../app/Domains/VehicleClaim/vue/viewList.vue').default);
Vue.component('view-list-comments-modal',
    require('../../app/Domains/VehicleClaim/vue/components/viewListCommentsModal').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    store,
    el: '#app',
    vuetify
});
