<template>
    <b-container>
        <b-row>
            <b-col>
                <b-form
                    @submit.prevent="(e) => {e.preventDefault()}"
                    @submit="generateContract(profile)"
                    @reset=""
                    v-if="show"
                >
                    <b-form-group
                        id="input-group-1"
                        label=""
                        label-for="input-1"
                        description="We'll never share your email with anyone else."
                    >
                        <!--                        <b-form-group id="input-group-2" label="Observatii:" label-for="observations">-->
                        <!--                            <b-form-textarea-->
                        <!--                                id="observations"-->
                        <!--                                v-model="contract.observations"-->
                        <!--                                placeholder="Enter observations"-->
                        <!--                                required-->
                        <!--                            ></b-form-textarea>-->
                        <!--                        </b-form-group>-->

                        <b-form-group id="" label="Tip Contract" label-for="contractType">
                            <b-form-select
                                id="observations"
                                v-model="contract.type"
                                :options="types"
                                placeholder="Enter observations"
                                required
                            ></b-form-select>
                        </b-form-group>

                        <b-button type="submit" variant="primary">Submit</b-button>
                        <b-button type="reset" variant="danger">Reset</b-button>
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col>
<!--                <div>-->
<!--                    {{ types }}-->
<!--                </div>-->
            </b-col>
        </b-row>

    </b-container>
</template>
<script>

import contractService from '../../../../../resources/js/services/contract';
import {mapActions, mapGetters} from "vuex";

export default {
    props: {
        invoiceId: {}
    },
    data() {
        return {};
    },
    computed: {
        contract: () => {
            return {
                type: '',
            }
        },
        ...mapGetters('contracts', ['types']),
        show: () => true,
    },
    mounted() {
        // this.getData();
        this.getTypes();
    },

    methods: {
        generateContract() {
            contractService.generateContract(
                this.contract,
                this.invoiceId
            )
        },
        ...mapActions('contracts', ['getTypes']),
    }

}
</script>
