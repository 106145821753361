import adminService from "../services/admin"

const state = {
    admin: {}, admins: [], adminsErrors: {message: '', errors: []},
};

const eventType = {
    getAdminDataSuccess: "getAdminDataSuccess",
    getAdminsSuccess: "getAdminsSuccess",
    getAdminDataError: "getAdminDataError",
    getAdminsDataError: "getAdminsDataError",
    createAdminSuccess: "createAdminSuccess"
}

const getters = {
    admin: () => {
        return state.admin;
    }, // partnerForFilterSelect: () => {
    //     return state.partners.map(partner => (
    //         {
    //             value: partner.id,
    //             text: partner.name
    //         }
    //     ))
    // },
    admins: () => {
        return state.admins
    }, adminsErrors: () => {
        return state.adminsErrors
    }
};

const mutations = {
    [eventType.getAdminDataSuccess](stateData, adminData) {
        state.admin = adminData;
    }, [eventType.createAdminSuccess](stateData, adminData) {
        state.admin = adminData;
    }, [eventType.getAdminsDataError](stateData, getAdminsDataError) {
        state.adminsErrors = getAdminsDataError;
    }, [eventType.getAdminsSuccess](stateData, admins) {
        state.admins = admins;
    }
};

const actions = {
    getAdminsForAdmin({commit}, options) {
        adminService.getAdminsForAdmin(options)
            .then((admins) => {
                commit(eventType.getAdminsSuccess, admins);
            }, (error) => {
                commit(eventType.getAdminDataError, error)
            })
    },

    createNewAdmin({commit}, data) {
        adminService.createNew(data)
            .then((admin) => {
                commit(eventType.createAdminSuccess, admin);
                window.location.href = (route('admin.admins.view-all-admins'))
            }, (error) => {
                commit(eventType.getAdminsDataError, JSON.parse(error.message))
            })
    }, deleteAdmin({commit}, adminId) {
        adminService.deleteAdmin(adminId).then(
            () => {
                flash('Admin Sters', 'success');
                window.location.reload();
                return true;
            }, (error) => {
                flash('Eroare de server', 'warning');
                return false;
            });


    }
};

export default {
    namespaced: true, state, getters, actions, mutations
}
