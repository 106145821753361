const getAdminsForAdmin = async ({sortBy, sortDesc, page, itemsPerPage}) => {
    try {
        let response = await axios({
            method: 'get', params: {
                sortBy: sortBy, sortDesc: sortDesc, page: page, itemsPerPage: itemsPerPage
            }, url: route('admin.admins.get-all')
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}
const createNew = async (data) => {
    try {
        let response = await axios({
            method: 'post', url: route('admin.admins.create'), data: data
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        throw new Error(JSON.stringify(error.response.data));
    }
}

const deleteAdmin = async (adminId) => {
    try {
        let response = await axios({
            method: 'delete', url: route('admin.delete-administrator') + adminId, data: {}
        });

        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        // flash('Eroare de server', 'warning')
        throw new Error(JSON.stringify(error.response.data));
    }
}
export default {
    getAdminsForAdmin, createNew, deleteAdmin,
}
