import Vue from "vue";

/**
 * Pages
 */
Vue.component(
    "coordinators-listing",
    require("./Pages/Coordinators/coordinatorsListing").default
);

Vue.component(
    "add-coordinator",
    require("./Pages/Coordinators/addCoordinator").default
);

Vue.component(
    "partners-listing",
    require("./Pages/Partners/listing").default
);

Vue.component(
    "add-partner",
    require("./Pages/Partners/add").default
);

/**
 * Components
 */

Vue.component(
    "add-edit-coordinator",
    require("./components/addEditCoordinator").default
);

Vue.component(
    "add-edit-partner",
    require("./components/addEditPartner").default
);

Vue.component(
    "add-administrator",
    require("./Pages/Admins/add").default
);

Vue.component(
    "add-edit-administrator",
    require("./components/addEditAdministrator").default
);

Vue.component(
    "administrator-listing",
    require("./Pages/Admins/adminListing").default
);
