<template>
    <b-container>
        <b-row>
            <b-col>
                <b-form
                    @submit.prevent="(e) => {e.preventDefault()}"
                    @submit="generateInvoice()"
                    @reset=""
                    v-if="show"
                >
                    <b-form-group
                        id="input-group-1"
                        label-for="input-1"
                    >
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="hasObservations"
                            name="checkbox-confirm-data"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Adauga observatii
                        </b-form-checkbox>

                        <b-form-group id="input-group-2" label="Nr Catalog:" label-for="catalog" v-if="hasObservations">
                            <b-form-input
                                id="catalog"
                                v-model="contract.catalog"
                                placeholder="Enter catalog"
                                :required="hasObservations"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="Artist:" label-for="artist" v-if="hasObservations">
                            <b-form-input
                                id="artist"
                                v-model="contract.artist"
                                placeholder="Enter artist"
                                :required="hasObservations"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="Piesa:" label-for="piesa" v-if="hasObservations">
                            <b-form-input
                                id="song"
                                v-model="contract.song"
                                placeholder="Enter song"
                                :required="hasObservations"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-checkbox
                            id="checkbox-2"
                            v-model="hasCoproduction"
                            name="checkbox-confirm-data"
                            :value="true"
                            :unchecked-value="false"
                            v-if="hasObservations"
                        >
                            Coproductie?
                        </b-form-checkbox>

                        <b-form-group id="input-group-2" label="Procent Roton in coproductie:" label-for="procent-roton" v-if="hasObservations && hasCoproduction">
                            <b-form-input
                                id="procent-roton"
                                v-model="contract.percentRoton"
                                placeholder="Enter Roton percentage"
                                :maxLength="3"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="Nume parteneri din coproductie:" label-for="coprodPartner" v-if="hasObservations && hasCoproduction">
                            <b-form-input
                                id="coprodPartner"
                                v-model="contract.coprodPartner"
                                placeholder="Enter coproduction partner"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="Tip proiect:" label-for="tipProiect" v-if="hasObservations">
                            <b-form-input
                                id="tipProiect"
                                v-model="contract.projectType"
                                placeholder="Enter project type"
                                :required="hasObservations"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2" label="Termen de plata:" label-for="paymentDate" v-if="hasObservations">
                            <b-form-datepicker
                                id="paymentDate"
                                v-model="contract.paymentDate"
                                class="mb-2"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                locale="en"
                                :required="hasObservations"
                            ></b-form-datepicker>
                        </b-form-group>
                        <div v-if="!contract.isValidDate">Data obligatorie</div>
                        <b-form-group id="input-group-2" label="Alte observatii:" label-for="otherObservations" v-if="hasObservations">
                            <b-form-textarea
                                id="otherObservations"
                                v-model="contract.otherObservations"
                                placeholder="Enter other observations"
                            ></b-form-textarea>
                        </b-form-group>

                        <b-button type="submit" variant="primary">Adauga observatii</b-button>
                        <b-button type="reset" variant="danger">Reset</b-button>
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col>
<!--                <b-card class="mt-3" header="Form Data Result">-->
<!--                    <pre class="m-0"></pre>-->
<!--                </b-card>-->
            </b-col>
        </b-row>
    </b-container>
</template>
<script>

import invoiceService from '../../../../../resources/js/services/invoice';

export default {
    props: {
        invoiceId: {
            type: Number
        },
        authType: {
            type: String,
            default: 'coordinator'
        }
    },
    data() {
        return {
            hasObservations: false,
            hasCoproduction: false,
        };
    },
    computed: {
        contract: () => {
            return {
                catalog: '',
                artist: '',
                song: '',
                percentRoton: 0,
                coprodPartner: '',
                projectType: '',
                otherObservations: '',
                paymentDate: '',
            }
        },
        show: () => true,
    },
    mounted() {
        this.getData();
    },

    methods: {
        async generateInvoice() {
            let response = await invoiceService.generateInvoice(
                this.contract,
                this.invoiceId,
                this.authType,
                this.hasObservations,
                this.hasCoproduction
            );
            if (response.success) {
                flash(response.message, 'success')

                window.location.href = route(this.authType + '.invoice.download-with-observations') + this.invoiceId;
                // window.location.href = route(this.authType + '.contract.download-docx') + this.invoiceId;
            } else {
                flash(response.message, 'warning');
            }
        }
    }

}
</script>
