<template>
    <div>
        <b-button v-b-toggle.sidebar-1>Filtre</b-button>
        <b-sidebar id="sidebar-1" title="Filtre" shadow>
            <div class="p-3">
                <nav class="mb-3">
                    <select-dropdown
                        :select-model="orderSelectModel"
                        emit-method="get-order-select-option"
                        @get-order-select-option="getFilterValue"
                    ></select-dropdown>
                    <template v-for="filter in filters">
                        <select-dropdown
                            v-if="filter.type==='selectDropdown'"
                            show-title="true"
                            :select-model="{
                                     label:filter.label,
                                     options:filter.options,
                                     name:filter.name
                                }"
                            emit-method="get-filter-value"
                            @get-filter-value="getFilterValue"
                        ></select-dropdown>
                        <form-input
                            v-if="filter.type==='input'"
                            show-title="true"
                            :input-model="{
                                     label:filter.label,
                                     options:filter.options,
                                     name:filter.name
                                }"
                            emit-method="get-filter-value"
                            @get-filter-value="getFilterValue"
                        ></form-input>
                    </template>
                </nav>
            </div>

        </b-sidebar>
    </div>
</template>

<script>
export default {
    props: ['emitMethod', 'filters', 'orderSelectModel'],
    data() {
        return {
            excludedFilters: ['Sortare']
        }
    },
    methods: {
        sendData: function () {
            this.$emit(this.emitMethod);
        },
        getFilterValue: function (filterModel) {
            if (!this.excludedFilters.includes(filterModel.name)) {
                this.filters[filterModel.name].value = filterModel.value;
            }
            this.sendData();
        },
    },
}

</script>
