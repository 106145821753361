<template>
    <div>
        <b-form-group
            :label="showTitle?(inputModel.label):''"
            :class="groupClasses"
        >
            <b-form-input
                :disabled="disabled"
                v-model="inputModel.value"
                @input="sendInputData"
                :name="inputModel.name"
                trim
            ></b-form-input>
        </b-form-group>
    </div>
</template>

<script>
export default {
    props: {
        inputModel: {},
        emitMethod: {},
        showTitle: {},
        disabled: {},
        groupClasses: {}
    },
    methods: {
        sendInputData: function () {
            this.$emit(this.emitMethod);
        },
    },
}
</script>
