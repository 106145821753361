const getData = async () => {
    try {
        let response = await axios({
            method: 'get',
            url: route('partner.invoice.get-data')
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}
const saveData = async (data) => {
    try {
        // PRINT FORM DATA
        // for (let key of data.entries()) {
        //     console.log(key[0] + ', ' +  key[1]);
        // }
        let response = await axios({
            method: 'post',
            url: route('invoice.add'),
            data: data,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                "Content-Type": "multipart/form-data"
            },
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        throw new Error(JSON.stringify(error.response.data.errors));
    }
}
const generateInvoice = async (data, invoiceId, authType, hasObservations, hasCoproduction) => {
    try {
        let response = await axios({
            method: 'post',
            url: route(authType + '.contract.generatePDFFromInvoiceWithObservations') + invoiceId,
            data: {
                data,
                hasObservations,
                hasCoproduction
            },
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
        });

        return {
            message: 'PDF Generat',
            success: true
        };
    } catch (error) {
        return {
            message: 'Eroare de server. Incercati mai tarziu',
            success: false
        };
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}

const sendToSigning = async (signingLink) => {
    try {
        let response = await axios({
            method: 'get',
            url: signingLink,
            data: {},
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
        });
        return {
            success: true
        };
    } catch (error) {
        flash('A aparut o eroare', 'warning');
        return {
            success: false
        };
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}

const sendToContab = async (contabLink) => {
    try {
        let response = await axios({
            method: 'get',
            url: contabLink,
            data: {},
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
        });
        return {
            success: true
        };
    } catch (error) {
        flash('A aparut o eroare', 'warning');
        return {
            success: false
        };
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}

const cancelInvoice = async (data) => {
    try {
        let response = await axios({
            method: 'post',
            url: route('admin.invoice.cancel-individual-invoice') + data.id,
            data: data,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
        });
        return {
            success: true
        };
    } catch (error) {
        flash('A aparut o eroare', 'warning');
        return {
            success: false
        };
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}

const addContract = async (data) => {
    try {
        // PRINT FORM DATA
        // for (let key of data.entries()) {
        //     console.log(key[0] + ', ' +  key[1]);
        // }
        let response = await axios({
            method: 'post',
            url: route('admin.invoice.add-invoice-contract') + data.get('invoiceId'),
            data: data,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                "Content-Type": "multipart/form-data"
            },
        });
        return {
            success: true
        };
    } catch (error) {
        flash('A aparut o eroare', 'warning');
        return {
            success: false
        };
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}
export default {
    getData,
    saveData,
    generateInvoice,
    sendToSigning,
    sendToContab,
    cancelInvoice,
    addContract
}
