<template>
    <b-form-group id="input-group-2" :label="labelName" label-for="pickerName">
        <b-form-datepicker
            id="pickerName"
            v-model="pickerModel.value"
            class="mb-2"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="en"
            @input="sendDatePickerData"
        ></b-form-datepicker>
    </b-form-group>
</template>

<script>
export default {
    name: "singleDatePickerVB",
    props: ['pickerModel', 'emitMethod', 'labelName'],
    data() {
        return {
            picker: null
        }
    },
    methods: {
        sendDatePickerData: function () {
            this.$emit(this.emitMethod);
        },
    }
}
</script>

<style scoped>

</style>
