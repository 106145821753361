import companyService from "../services/company"

const state = {
    company: {},
    companies: [],
};

const eventType = {
    getCompanyDataSuccess: "getCompanyDataSuccess",
    getCompaniesSuccess: "getCompaniesSuccess",
    getCompanyDataError: "getCompanyDataError",
}

const getters = {
    company: () => {
        return state.company;
    },
    companies: () => {
        return state.companies
    },
    companyForFilterSelect: () => {
        return state.companies.map(company => (
            {
                value: company.id,
                text: company.name
            }
        ))
    },
};

const mutations = {
    [eventType.getCompanyDataSuccess](stateData, companyData) {
        state.company = companyData;
    },
    [eventType.getCompanyDataError](stateData, getCompanyDataError) {
        state.company = null
        console.log(getCompanyDataError);
    },
    [eventType.getCompaniesSuccess](stateData, companies) {
        state.companies = companies;
    }
};

const actions = {
     async getCompaniesForCoordinators({commit}, options) {
         await companyService.getCompaniesForCoordinators(options)
             .then(
                 (companies) => {
                     commit(eventType.getCompaniesSuccess, companies);
                 },
                 (error) => {
                     commit(eventType.getCompanyDataError, error)
                 }
             )
     },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
