<template>
    <div>
        <b-form-group
            :label="showTitle?(inputModel.label):''"
            :class="groupClasses"
        >
            <b-form-textarea
                :disabled="disabled"
                v-model="inputModel.value"
                @input="sendInputData"
                :name="inputModel.name"
                trim
            ></b-form-textarea>
        </b-form-group>
    </div>
</template>

<script>
export default {
    props: {
        inputModel: {
            type: Object,
            default: () => {
                return {
                    label: 'Label',
                    name: 'name',
                    value: "",
                }
            }
        },
        emitMethod: {
            default: () => {
                return null;
            }
        },
        showTitle: {},
        disabled: {},
        groupClasses: {},
    },

    methods: {
        sendInputData: function () {
            if (this.emitMethod != null) {
                this.$emit(this.emitMethod);
            }
        },
    },
}
</script>
