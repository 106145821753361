<template>
  <v-autocomplete
      filled
      rounded
      solo
      chips
      :label="label"
      :items="options"
      :item-text="itemText"
      :item-value="itemValue"
      @change="onSelectChange()"
      v-model="itemValue"
      return-object
  ></v-autocomplete>
</template>

<script>

export default {
  name: "search-select",
  props: {
    options: {
      type: Array,
      default: []
    },
    label: {
      type: String,
      default: ""
    },
    itemText: {
      type: String,
      default: 'value'
    },
    itemValue: {
      type: String,
      default: 'id'
    }
  },
  data() {
    return {
      itemValue: {
        id: '',
        value: ''
      },
    };
  },
  mounted() {
  },

  methods: {
    onSelectChange(e) {
      this.$emit("selectCoordinatorOption", this.itemValue);
    }
  }
}
</script>

<style>

</style>
