<template>

    <v-container>
        <v-form
            ref="form"
        >
            <search-select
                :options="selectCoordinators"
                label="Select Coordinator"
                @selectCoordinatorOption="selectOption($event)"
            />
            <single-date-picker
                label = 'Data Emiterii'
                @datePickerValue="getDatePickerValue($event)"
            />
            <div v-for="file in files">
                <span>{{ file.name }}</span>
            </div>
            <v-btn @click="uploadDialog = true" icon>
                <i class="fas fa-plus"></i>
                <span>open upload component</span>
            </v-btn>
            <custom-upload-files :dialog.sync="uploadDialog"
                                 :multiple="true"
                                 @filesUploaded="processUpload($event)"
            ></custom-upload-files>
            <v-btn
                color="success"
                class="mr-4"
                @click="submit"
            >
                Submit
            </v-btn>
        </v-form>
        <div v-for="error in invoicesErrors">
            {{ error }}
        </div>
        <div v-if="invoiceUploadResponse">
            Facturile au fost urcate cu succes
        </div>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SearchSelect from "../components/search-select";
import SingleDatePicker from "../../../Common/vue/views/components/input/singleDatePicker";

export default {
    name: "uploadInvoice",
    components: {SingleDatePicker, SearchSelect},
    props: {},
    data() {
        return {
            uploadDialog: false,
            files: [],
            issueDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            issueDateMenu: false,
        };
    },
    computed: {
        ...mapGetters('invoices', ['getterUploadedFile', 'invoicesErrors', 'invoiceUploadResponse']),
        ...mapGetters('coordinators', ['coordinator', 'selectCoordinators', 'getterSelectedOption', 'getterDatePickerData']),
        show: () => true,
    },
    watch: {
        invoicesErrors: function (newInvoiceErrors, oldInvoiceErrors) {
            if (newInvoiceErrors == false) {
                this.files = [];
            }
        }
    },
    mounted() {
        // this.getData();
        this.getCoordinatorsForPartner();
    },

    methods: {
        ...mapActions('invoices', ['getData', 'saveData', 'setUploadedFileData']),
        ...mapActions('coordinators', ['getCoordinatorData', 'setSelectData', 'getCoordinatorsForPartner', 'setDatePickerData']),
        processUpload(files) {
            this.files = files;
            this.setUploadedFileData(files);
            return files;
        },
        selectOption(event) {
            this.setSelectData(event);
        },
        getDatePickerValue(event) {
            this.setDatePickerData(event);
        },
        submit() {
            this.$refs.form.validate() //pt submit la form - nice to have validations
            let formData = new FormData();
            this.files.forEach(entry => {
                formData.append('files[]', entry);
            });

            // Validare data optiunea selectata e obiect gol, daca e obiect gol, ar trebui sa nu o bage in form data
            // console.log(Object.keys(this.getterSelectedOption).length === 0 && this.getterSelectedOption.constructor === Object);
            formData.append('selectedCoordinatorId', this.getterSelectedOption.id);
            formData.append('issueDate', this.getterDatePickerData);

            this.saveData(formData);
        },
    }
}
</script>

<style scoped>
.v-btn--icon.v-size--default {

    width: inherit;
}

</style>
