const getCompaniesForCoordinators = async () => {
    try {
        let response = await axios({
            method: 'get',
            params: {},
            url: route('invoices.companies.get-all')
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}

export default {
    getCompaniesForCoordinators,
}
