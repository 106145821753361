<template>
    <add-edit-coordinator
        :coordinator={}
        :submitForm="createNewCoordinator"
    >
        <template v-slot:title>
            <span>
                Adauga Coordonator
            </span>
        </template>
    </add-edit-coordinator>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters('coordinators', ['coordinators'])
    },
    mounted() {
        // this.getCoordinatorsForAdmin({
        //     page: 1
        // })
    },
    methods: {
        ...mapActions('coordinators',['createNewCoordinator']),
    }

}
</script>
