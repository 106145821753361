const generateContract = async (data, invoiceId) => {
    try {
        let response = await axios({
            method: 'post',
            url: route('coordinator.contract.create-contract-docx') + invoiceId,
            data: data,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
        });
        window.location.href = route('coordinator.contract.download-docx') + invoiceId;
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}

const getTypes = async (data) => {

    try {
        let response = await axios({
            method: 'get',
            params: data,
            url: route('contracts.get-types')
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}


export default {
    generateContract,
    getTypes
}
