const getCoordinatorData = async () => {
    try {
        let response = await axios({
            method: 'get',
            url: route('coordinator.get-data')
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}
const getCoordinatorsForAdmin = async ({sortBy, sortDesc, page, itemsPerPage}) => {
    try {
        let response = await axios({
            method: 'get',
            params: {
                sortBy: sortBy,
                sortDesc: sortDesc,
                page: page,
                itemsPerPage: itemsPerPage
            },
            url: route('admin.coordinators.get-all')
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}
const getCoordinatorsForPartner = async () => {
    try {

        let response = await axios({
            method: 'get',
            params: {},
            url: route('coordinators.get-all')
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}
const createNew = async (data) => {
    try {
        let response = await axios({
            method: 'post',
            url: route('admin.coordinators.create'),
            data: data
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        throw new Error(JSON.stringify(error.response.data));
    }
}

const saveData = async (data) => {
    try {
        let response = await axios({
            method: 'patch',
            url: route('partner.profile.store-edit'),
            data: data
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}

const getInvoiceListingData = async (data) => {

    try {
        let response = await axios({
            method: 'get',
            params: data,
            url: route('invoices.get-invoice-list')
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}

const deleteCoordinator = async (data) => {

    try {
        let response = await axios({
            method: 'get',
            params: data,
            url: route('admin.coordinators.delete')
        });
        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        return null;
    }
}

const deleteSoftCoordinator = async (coordinatorId) => {
    try {
        let response = await axios({
            method: 'delete', url: route('admin.delete-soft-coordinator') + coordinatorId, data: {}
        });

        return response.data;
    } catch (error) {
        //TODO: Show flash message
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else {
            console.error(error);
        }
        // flash('Eroare de server', 'warning')
        throw new Error(JSON.stringify(error.response.data));
    }
}

export default {
    getCoordinatorData,
    getCoordinatorsForAdmin,
    getCoordinatorsForPartner,
    saveData,
    createNew,
    getInvoiceListingData,
    deleteCoordinator,
    deleteSoftCoordinator
}
