<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <a class="nav-link" :href="addPartnerPageUrl">Adauga Partener</a>
            </div>
        </div>
        <div class="row">

            <div class="col">
                <data-table-listing
                    :headers="tableHeader"
                    :get-data="getPartnersForAdmin"
                    :items="partners"
                    :items-per-page="-1"
                >
                    <template v-slot:action-buttons>
                        <!--                                               @click="createPacks(item)" :loading="item.createloading" :disabled="createloading"-->
                        <v-btn color="success" v-on:click="impersonate($event)">
                            Impersoneaza
                        </v-btn>
                        <!--                       @click="excludeRequest(item)" :loading="item.cancelloading" :disabled="cancelloading"-->
                        <v-btn color="error" v-on:click="openShowDeletePartnerConfirmationModal($event)"
                        >Sterge
                        </v-btn>

                    </template>
                </data-table-listing>
                <v-dialog
                    v-model="showDeletePartnerConfirmationDialog"
                    persistent
                    max-width="290"
                >
                    <v-card>
                        <v-card-title class="text-h5" style="word-break: break-word;">
                            Confirmare stergere
                        </v-card-title>
                        <v-card-text>
                            Sunteti sigur ca doriti sa stergeti partenerul?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="clickShowDeletePartnerConfirmationModal(false)"
                            >
                                Nu
                            </v-btn>
                            <v-btn
                                color="green darken-1"
                                text
                                @click="clickShowDeletePartnerConfirmationModal(true)"
                            >
                                Da
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import auth from "../../../../../../resources/js/services/auth";

export default {
    props: {
        addPartnerPageUrl: {}
    },
    data() {
        return {
            tableHeader: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Name', value: 'name'},
                {text: 'Email', value: 'email'},
                {text: 'Phone number', value: 'phone_number'},
                {text: 'Address', value: 'address'},
                {text: 'Actions', value: 'actions'},
            ],
            deleteElementId: '',
            showDeletePartnerConfirmationDialog: false
        }
    },
    computed: {
        ...mapGetters('partners', ['partners'])
    },
    mounted() {
        // this.getCoordinatorsForAdmin({
        //     page: 1
        // })
    },
    methods: {
        ...mapActions('partners', ['getPartnersForAdmin', 'deleteSoftPartner']),
        impersonate: (x) => {
            let elementId = x.target.parentElement.parentElement.querySelector('[name="id"]').value;
            auth.impersonate(elementId).then(() => {
                window.location.href = "/";
            });
        },
        openShowDeletePartnerConfirmationModal(event) {
            this.deleteElementId = event.target.parentElement.parentElement.querySelector('[name="id"]').value;
            this.showDeletePartnerConfirmationDialog = true;
        },
        clickShowDeletePartnerConfirmationModal(agree) {
            if (agree) {
                this.deleteSoftPartner(this.deleteElementId);
            }
            this.showDeletePartnerConfirmationDialog = false;
        }
    }
}
</script>
