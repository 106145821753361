import contractsService from "../services/contract"

const state = {
    types: [],
};

const eventType = {
    getTypes: "getTypes",
    getTypesSuccess: "getTypesSuccess",
    getTypesDataError: "getTypesDataError",
}

const getters = {
    types: () => {
        return state.types;
    },
};

const mutations = {
    [eventType.getTypesSuccess](stateData, types) {
        state.types = types;
    }
};

const actions = {
    getTypes({commit}) {
        contractsService.getTypes()
            .then(
                (types) => {
                    commit(eventType.getTypesSuccess, types);
                },
                (error) => {
                    commit(eventType.getCoordinatorDataError, error)
                }
            )
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
