import profileService from "../services/profile"

const state = {
    profile: {},
    successMessage: '',
};

const eventType = {
    getData: "getData",
    saveData: "saveData",
    getDataSuccess: "getDataSuccess",
    getDataError: "getDataError",
}

const getters = {
    profile: () => {
        return state.profile;
    },
};

const mutations = {
    [eventType.getDataSuccess](stateData, profileData) {
        state.profile = profileData;
    },
    [eventType.getDataError](stateData, getDataError) {
        // state.profile = null
        console.log(getDataError);
    },
};

const actions = {
    getData({commit}) {
        profileService.getData()
            .then(
                (profileData) => {
                    commit(eventType.getDataSuccess, profileData);
                },
                (error) => {
                    commit(eventType.getDataError, error)
                }
            )
    },
    saveData({commit}, data) {
        profileService.saveData(data)
            .then(
                (profileData) => {

                    const successMessage = 'Profilul a fost editat cu succes!';
                    commit(eventType.getDataSuccess, profileData);
                    flash(successMessage, 'success')
                },
                (error) => {
                    commit(eventType.getDataError, error)
                    flash('Server Error', 'warning')
                }
            )
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
